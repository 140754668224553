<template>
  <div class="product">
    <banner img="../assets/img/bgtop.jpg" title="产品中心"/>
    <div id="selectType">
      <div id="types">
        <ul>
          <li><a target="_blank">产品中心</a></li>
<!--          <li><a href="http://localhost:8080" target="_blank">图书案例</a></li>-->
<!--          <li><a href="http://localhost:8080" target="_blank">其他案例</a></li>-->
        </ul>
      </div>
    </div>

    <div class="product-content" v-loading="loading">
      <div id="product-list">
        <ul>
          <li v-for="data in datalist" :key="data">
            <a href="#" @click.prevent="productClick(data.title,data.created_at,data.content)">
            <div>
<!--              <img src="https://nwzimg.wezhan.cn/contents/sitefiles2056/10281255/images/38884180.jpg">-->
              <img :src="data.coverImage">
            </div>
            <div>
              <p>{{data.title}}</p>
            </div>
            </a>
          </li>
        </ul>
      </div>
      <div id="product-page">
        <el-pagination
            hide-on-single-page
            :current-page="listQuery.pageNum"
            :page-size="listQuery.pageCount"
            @current-change="currPageChange"
            background
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/Banner";

export default {
  data() {
    return {
      loading: true,
      listQuery: {
        "pageNum": 1,
        "pageCount": 9,
        "type": 'product'
      },
      total: 0,
      datalist: []
    };
  },
  components: {
    Banner
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$axios({
        url:  this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.datalist = res.data.msg.articles
          this.total = res.data.msg.total
        }
        this.loading = false
      })
    },
    currPageChange(pageNum){
      if(pageNum!==null) {
        this.listQuery.pageNum = pageNum
        this.loadData()
      }
    },
    productClick(title,date,content){
      localStorage.setItem("type",'case')
      localStorage.setItem("title",title)
      localStorage.setItem("date",date)
      localStorage.setItem("content",content)
      this.$router.push("/detail")
    },
  },
  created() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

.el-pagination {
  text-align: center;
}

.el-pager li {
  background-color: rgb(253 253 253) !important;

}

.product {
  width: 100%;
  height: 100%;
  //background-color: rgb(247,247,247);
  background-color: #f7f7f7;

  #selectType {
    width: 100%;
    height: 70px;
    background-color: white;
    //border-bottom: 1px solid #ececec;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);

    #types {
      margin: 0 auto;
      width: 1200px;
      height: 70px;

      ul {
        height: 70px;

        li {
          height: 70px;
          float: left;
          text-align: center;
          line-height: 70px;

          a {
            padding: 0 20px;
            display: block;
          //}
          //
          //a:hover, a:active {
            border-bottom: 2px solid #0068b6;
            color: #0068b6;
          }

        }
      }
    }
  }

  .product-content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;

    #product-list {
      ul {
        margin-top: 50px;

        li {
          display: inline-block;
          overflow: hidden;
          margin-left: 36px;
          margin-top: 20px;
          width: 370px;
          height: 355px;
          text-align: center;
          background-color: white;
          vertical-align: middle;
          border: 1px solid rgb(214 200 200);

          //rgb(18 186 186)           rgb(18 186 186)   rgb(214 200 200)

          div:nth-child(1){
            height: 300px;
            width: 370px;
            line-height: 300px;
            overflow: hidden;
            img {
              //width: 370px;
              //height: 300px;
              height: 90%;
              width: 100%;
              object-fit: contain;
              vertical-align: middle;
              margin: 0 auto;
              transition:transform 0.4s;
            }
            img:hover{
              transform: scale(1.1);
            }
          }



          p {
            height: 54px;
            line-height: 55px;
            font-size: 16px;
            text-align: center;
            width: 370px;
            border-top: 1px solid #ececec;
          }
          p:hover{
              color: #017bc3;
              text-decoration: underline;
          }
        }
        li:nth-child(3n-2){
          margin-left: 0;
        }
      }
    }

    #product-page {
      margin-top: 50px;
    }
  }
}


</style>
